import { Link } from "gatsby";
import React from "react";
import "./style.css";
import LogoLink from "./logo-link";
import MobileHeader from "./mobile-header";
import { navLinks } from "../../data/links";
import { useReadLocalStorage } from "usehooks-ts";
import { Avatar } from "evergreen-ui";
import { getAvatarString } from "../../utils/tools";
import { AppUser } from "../../model/types";

const Header = () => {
  const loggedIn = useReadLocalStorage<boolean>("logged_in");
  const user = useReadLocalStorage<AppUser>("user");

  return (
    <div className="container">
      <header
        className={"hidden lg:flex flex-row flex-wrap items-center justify-center py-4 relative"}
      >
        <LogoLink />
        <ul className="flex flex-row items-center justify-center">
          {navLinks.map((item, index) => (
            <li key={index}>
              <Link to={item.href} className="font-medium text-xs p-3">
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
        {loggedIn ? (
          <ul className="flex flex-row items-center justify-center font-medium text-xs ml-auto pt-1">
            <li>
              <Link to="/dashboard/wallets/deposit" className="p-3">
                Deposit
              </Link>
            </li>
            <li>
              <Link to="/dashboard/wallets/withdraw" className="p-3">
                Withdraw
              </Link>
            </li>
            <li>
              <Link to="/dashboard/orders" className="p-3 mr-4">
                Transactions
              </Link>
            </li>
            <li>
              <Link to="/dashboard">
                <Avatar name={getAvatarString(user)} size={48} />
              </Link>
            </li>
          </ul>
        ) : (
          <div className={"ml-auto flex items-center"}>
            <Link to="/signin" className="py-2 px-5 text-xs font-semibold">
              Login
            </Link>
            <Link
              to="/signup"
              className="ml-3 py-4 px-5 text-xs font-semibold bg-blue-700 text-white rounded-lg"
            >
              Get started
            </Link>
          </div>
        )}
      </header>
      <MobileHeader />
    </div>
  );
};

export default Header;
