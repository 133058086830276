import React from "react";
import FacebookLogo from "./../../images/social/fb.svg";
import TwitterLogo from "./../../images/social/twitter.svg";
import RedditLogo from "./../../images/social/reddit.svg";
import MediumLogo from "./../../images/social/medium.svg";
import GithubLogo from "./../../images/social/github.svg";
import SteemitLogo from "./../../images/social/steemit.svg";
import { useStaticQuery, graphql, Link } from "gatsby";

const FooterNav = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteShortName
          }
        }
      }
    `
  );

  return (
    <div className="grid grid-cols-2 sm:grid-cols-7 text-xs gap-8 ml-2 sm:ml-4">
      <div>
        <h5 className="mb-4 font-semibold">About us</h5>
        <ul className="text-gray-500 flex flex-col gap-y-2">
          <li>
            <Link to="/terms-of-use">Terms of Use</Link>
          </li>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/cookies-policy">Cookies Policy</Link>
          </li>
          <li>Careers</li>
          <li>{"About " + site.siteMetadata.siteShortName}</li>
        </ul>
      </div>
      <div>
        <h5 className="mb-4 font-semibold">Support</h5>
        <ul className="text-gray-500 flex flex-col gap-y-2">
          <li>
            <Link to="/support">Submit a Ticket</Link>
          </li>
          <li>
            <Link to="/support">Knowledge Base</Link>
          </li>
        </ul>
      </div>
      <div>
        <h5 className="mb-4 font-semibold">Service</h5>
        <ul className="text-gray-500 flex flex-col gap-y-2">
          <li>Help Center</li>
          <li>
            <Link to="/fees">Fees</Link>
          </li>
          <li>
            <Link to="/dashboard/affiliates">Referrals</Link>
          </li>
          <li>
            <Link to="/signin">API Documentation</Link>
          </li>
        </ul>
      </div>
      <div className="sm:col-span-2">
        <h5 className="mb-4 font-semibold">Legal</h5>
        <ul className="text-gray-500 flex flex-col gap-y-2">
          <li>
            <Link to="/aml-cft-policy">{"AML&CFT Policy"}</Link>
          </li>
          <li>Security</li>
          <li>
            <Link to="/risk-warning">Risk Warning</Link>
          </li>
        </ul>
      </div>
      <div className="col-span-2">
        <h5 className="mb-4 font-semibold">Community</h5>
        <div className="flex flex-wrap gap-4">
          <img className="w-7 h-7" src={FacebookLogo}></img>
          <img className="w-7 h-7" src={TwitterLogo}></img>
          <img className="w-7 h-7" src={RedditLogo}></img>
          <img className="w-7 h-7" src={MediumLogo}></img>
          <img className="w-7 h-7" src={GithubLogo}></img>
          <img className="w-7 h-7" src={SteemitLogo}></img>
        </div>
      </div>
    </div>
  );
};

export default FooterNav;
